<template>
  <section class="shared-kit-earnings">
    <div class="shared-kit-earnings__amount">
      <span>+ {{ parsedAmount }}</span>
    </div>
    <ClientOnly>
      <div class="shared-kit-earnings__icons">
        <div v-if="icon" class="shared-kit-earnings__icons-main">
          <UiImage
            v-if="icon"
            :src="icon"
            :width="iconSize"
            :height="iconSize"
            class="shared-kit-earnings__icon-coin"
          />
        </div>
        <div v-if="showRag" class="shared-kit-earnings__icons-rag">
          <LazySvgoRagIcon :width="iconSize" :height="iconSize" />
        </div>
      </div>
    </ClientOnly>
  </section>
</template>

<script setup lang="ts">
import type { ISharedKitEarningsProps } from './SharedKitEarnings.types';
import { Colors } from '~/constants/colors.constants';

const props = defineProps<ISharedKitEarningsProps>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const iconSize = computed(() => changeXL(40, 24));

const parsedAmount = computed(() => {
  if (Array.isArray(props.amount)) {
    return `${props.amount[0]}-${props.amount[1]}`;
  }

  return `${props.amount}`;
});

const styleVariables = computed(() => {
  if (props.showRag) {
    return {
      coinShadow: Colors.NEUTRAL.BLACK,
      eventColor: GlobalUtils.CSS.getBackgroundColor({
        colorStops: [props.color as string, Colors.ADDITIONAL.RAFFLE_GOLD],
        toDirection: '90deg',
      }),
    };
  }

  return {
    coinShadow: GlobalUtils.CSS.getBackgroundColor(props.color),
    eventColor: GlobalUtils.CSS.getBackgroundColor(props.color),
  };
});
</script>

<style scoped lang="scss">
.shared-kit-earnings {
  --event-color: v-bind('styleVariables.eventColor');
  --event-shadow: v-bind('styleVariables.coinShadow');
}
</style>

<style scoped lang="scss" src="./SharedKitEarnings.scss" />

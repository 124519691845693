<template>
  <section class="shared-kit-price">
    <div v-if="variant.type === ESharedKitPriceTypes.MONEY" class="shared-kit-price__current">
      <BaseCurrency v-if="variant.price">{{ variant.price }}</BaseCurrency>
      <template v-else> {{ $t('forFree') }}</template>
    </div>
    <div v-if="variant.type === ESharedKitPriceTypes.MONEY && variant.oldPrice" class="shared-kit-price__old">
      <BaseCurrency>{{ variant.oldPrice }}</BaseCurrency>
    </div>
    <BaseCurrency v-if="variant.type === ESharedKitPriceTypes.COINS" class="shared-kit-price__coins" is-event-coin>
      {{ variant.amount }}
    </BaseCurrency>
  </section>
</template>

<script setup lang="ts">
import { ESharedKitPriceTypes, type ISharedKitPriceProps } from './SharedKitPrice.types';

const props = defineProps<ISharedKitPriceProps>();

const styleVariables = computed(() => ({
  color:
    props.variant.type === ESharedKitPriceTypes.COINS ? GlobalUtils.CSS.getBackgroundColor(props.variant.color) : '',
}));
</script>

<style scoped lang="scss">
.shared-kit-price {
  --coin-color: v-bind('styleVariables.color');
}
</style>

<style scoped lang="scss" src="./SharedKitPrice.scss" />
